<!--
* @Author: tianzl
* @Date: 2023-10-19 17:05:16
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 17:05:16
* @Description: 跟进-无效
 -->
 <template>
  <span>
    <div>
      <span class="mr8 event-type">{{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}</span>
      <span class="event-type">{{ trip.followUserName }}</span>
    </div>
    <div>
      <span class="label mr8">{{$t('跟进方式')}}</span>
      <span class="value">{{ trip.followMode | getdectText(trip, 'followMode', $store,'1010') }}</span>
    </div>
    <div>
      <span class="label mr8">{{$t('回访结果')}}</span>
      <span class="value">{{trip.visitResult | getdectText(trip, 'visitResult', $store,'1019') }}</span>
    </div>
    <template v-if="trip.visitResult && ['1019002', '1019004'].includes(trip.visitResult)">
      <div>
        <span class="label mr8">{{$t('跟进结果')}}</span>
        <span class="value">{{trip.followResult | getdectText(trip, 'followResult', $store,'1011') }}</span>
        <template v-if="['1011004', '1011019'].includes(trip.followResult) && trip.extendInfo && (trip.extendInfo.appointmentTypeCode === '2001002' || trip.extendInfo?.typeName)">
            <span> | </span>
            <span class="value">{{ trip.extendInfo.appointmentTypeCode === '2001002' ? `${$t('预约到店')}` : $t(trip.extendInfo.typeName) }}</span>
          </template>
      </div>
      <div v-if="trip.followResult === '1011017'">
        <span class="label mr8">{{ $t('分配门店') }}</span>
        <span class="value">{{ trip.sysRemark }}</span>
      </div>
      <div v-if="trip.followResult === '1011019'">
        <span class="label mr8">{{ $t('分配门店') }}</span>
        <span class="value">{{ trip.extendInfo?.assignDealerName }}</span>
      </div>

      <div v-if="['1011004', '1011019'].includes(trip.followResult)" style="display: flex">
        <span class="label mr8">{{ $t('预约信息') }}</span>
        <!--  预约到店 -->
        <span class="value">
          <div>{{ trip.extendInfo.appointmentTime }}</div>
          <!-- 集中试驾，展示活动名称，预约车系 -->
          <template v-if="trip.extendInfo.type === '2002003'">
            <div class="value">
              {{ trip.extendInfo.activityTitle || '--' }}
            </div>
            <div class="value">
              {{ trip.extendInfo.seriesName || '--' }}
            </div>
          </template>
          <!-- 预约试驾 到店试驾和上门试驾展示车型&车牌号 -->
          <div v-if="trip.extendInfo.appointmentTypeCode === '2001001' && trip.extendInfo.type !== '2002003'" class="value">
            {{ trip.extendInfo.vehicleName ? trip.extendInfo.vehicleName : '' }}{{ trip.extendInfo.vehicleName && trip.extendInfo.carNumber ? ' | ' : '' }}{{ trip.extendInfo.carNumber }}
          </div>
          <!-- 上门试驾 展示地址 -->
          <div v-if="trip.extendInfo.type === '2002002'" class="value">
            {{ trip.extendInfo.driveProvinceName }}{{ trip.extendInfo.driveCityName }}{{ trip.extendInfo.driveDistrictName }}{{ trip.extendInfo.driveAddress }}
          </div>
        </span>
      </div>
      <template v-if="trip.visitResult === '1019002'">
        <div>
          <span class="label mr8">{{$t('销售顾问')}}</span>
          <span class="value">{{trip.assignUserName }}</span>
        </div>
        <div>
          <span class="label mr8">{{$t('试驾等级')}}</span>
          <span class="value">{{ trip.tryDriveLevel | getdectText(trip, 'tryDriveLevel', $store,'1012') }} </span>
        </div>
      </template>
    </template>
    <div style="display: flex;">
      <span class="label mr8">{{$t('跟进记录')}}</span>
      <span class="value" style="flex:1">{{ trip.followRemark }}</span>
    </div>
  </span>
  </template>
<script>
export default {
  filters:{
    getdectText(val,trip,key,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[key]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[key])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
  <style scoped lang="less" >
  @import "../style/common.less";
  </style>